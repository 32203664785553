<script>
import {
  required,
  integer,
  minValue,
  email,
  maxLength,
  requiredIf,
  decimal
} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import VuePhoneNumberInput from "vue-phone-number-input";
import Layout from "../../layouts/main";
import * as moment from "moment";
// import * as cloneDeep from "lodash.clonedeep";
import {
  FETCH_ALL_PRICE,
  POST_SUBSCRIPTION,
} from "@/state/actions.type";
// import CopyLink from '../../../components/copy-link.vue';
import cloneDeep from 'lodash.clonedeep';
import Breadcrumb from '../../../components/breadcrumb.vue';
import switchInput from '../../../components/switch-input.vue';

export default {
  components: {
    DatePicker,
    VuePhoneNumberInput,
    // CopyLink,
    Layout,
    Breadcrumb,
    switchInput
  },
  validations: {
    form: {
      price: {
        required,
      },
      description: {},
      quantity: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.subscription_type === 'REGULAR';
        }),
        integer,
        minValue: minValue(1),
        maxLength: maxLength(5),
      },
      notes: {
        $each: {
          key: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.value;
            }),
          },
          value: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.key;
            }),
          },
        },
      },
      initial_amount: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.subscription_type === 'ONDEMAND';
        }),
        decimal,
        minValue: minValue(1),
        maxLength: maxLength(10),
      },
      allow_accumulate: {},
      started_at: {
        required: requiredIf(function (nestedModel) {
          return !this.immediately && nestedModel.subscription_type === 'REGULAR';
        })
      },
      customer_name: {},
      customer_phone_number: {
        required: requiredIf(function (element) {
          return element.notify_by_phone;
        })
      },
      customer_email_address: {
        required: requiredIf(function (element) {
          return element.notify_by_email;
        }),
        email,
      },
      recurrance_count: {
        required,
        integer,
        minValue: minValue(1)
      },
      additional_costs: {
        $each: {
          key: {
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.value;
            }),
          },
          value: {
            integer,
            minValue: minValue(1),
            maxLength: maxLength(5),
            required: requiredIf(function (nestedModel) {
              return !!nestedModel.key;
            }),
          },
        },
      },
      notify_by_phone: {},
      notify_by_email: {},
      expiry_date: {
        required,
      }
    },
  },
  data() {
    return {
      typesubmit: false,
      subscription_link_ref:"",
      form: {
        price: "",
        description: "",
        quantity: "",
        notes: null,
        allow_accumulate: "N",
        started_at: "",
        customer_name: "",
        customer_phone_number: "",
        customer_email_address: "",
        recurrance_count: 1,
        additional_costs: null,
        notify_by_email: false,
        notify_by_phone: false,
        initial_amount: null,
        expiry_date: "",
        subscription_type: "REGULAR",
        success_url: `${process.env.VUE_APP_SUBSCRIPTION_BASE_URL}/success.html`,
        failure_url: `${process.env.VUE_APP_SUBSCRIPTION_BASE_URL}/failure.html`,
        pending_url: `${process.env.VUE_APP_SUBSCRIPTION_BASE_URL}/pending.html`
      },
      additional_notes: false,
      additional_costs: false,
      isProcessing: false,
      priceList: [],
      immediately: false,
      phoneNumberVal: null,
      currencies: {
        VND: "VN",
        THB: "TH",
        SGD: "SG",
        IDR: "ID",
        PHP: "PH",
        USD: "US",
        AUD: "AU",
        EUR: "EU",
        KRW: "KR"
      },
      currencyList: [
        "IDR",
        "PHP",
        "SGD",
        "THB",
        "VND",
        "MYR",
        "TWD",
        "HKD",
        "KHR",
        "WST",
        "USD",
        "INR",
        "AUD",
        "EUR",
        "KRW"
      ],
      orderLink: "",
      cycles: {
        H: "Hour",
        D: "Day",
        W: "Week",
        M: "Month",
        Q: "Quarter",
        Y: "Year"
      },
    };
  },
  watch: {
    additional_notes(value) {
      if (value) {
        this.form.notes = [
          {
            key: "",
            value: "",
          },
        ];
      } else {
        this.form.notes = null;
      }
    },
    additional_costs(value) {
      if (value) {
        this.form.additional_costs = [
          {
            key: "",
            value: "",
          },
        ];
      } else {
        this.form.additional_costs = null;
      }
    },
    immediately(value) {
      if (value) {
        this.form.started_at = "";
        this.form.expiry_date = new Date(new Date().getTime() + 5 * 24 * 3600 * 1000)
      } else {
        this.form.expiry_date = ""
      }
    },
    'form.customer_phone_number'(value) {
      if(!value) {
        this.form.notify_by_phone = false;
      }
    },
    'form.customer_email_address'(value) {
      if(!value) {
        this.form.notify_by_email = false;
      }
    },
  },
  created() {
    this.$store.dispatch(`subscription/${FETCH_ALL_PRICE}`).then(response => {
      this.priceList = response.content;
    });
  },
  computed: {
    amount() {
      if(this.form.price) {
        if(this.form.price.plan_type === "ONDEMAND") {
          return 'As per request';
        }
        if(this.form.price.amount && this.form.price.currency) {
          return this.formatAmount(this.form.price.amount, this.form.price.currency)
        }
      }
      return '';
    },
    frequency() {
      if(this.form.price.plan_type === "ONDEMAND") {
        return 'Upon demand';
      }
      if(this.form.price.frequency){
        return `${this.form.price.frequency} ${this.cycles[this.form.price.period]}`;
      }else{
        return ''
      }
      
    },
    items() {
      return [
        {
          text: this.$t('menuitems.subscriptions.text'),
          to: { name: 'Subscription', query: {tab: 'Subscription'} }
        },
        {
          text: 'Create New Subscription',
          active: true,
        },
      ];
    }
  },
  filters: {
        expiry_date(value) {
            return value ? moment(value).format("DD MMM, YYYY hh:mm A") : '';
        },
    },

  methods: {
    setExpiryDate() {
      this.immediately = false;
      this.form.expiry_date = "";
    },
    submitSubscription() {
      this.isProcessing = true;
      this.typesubmit = true;
      this.$v.form.$touch();

      if (this.$v.form.$invalid || (this.phoneNumberVal && this.phoneNumberVal.formattedNumber && !this.phoneNumberVal.isValid)) {
        this.isProcessing = false;
        return false;
      }

      let formData = cloneDeep(this.form);

      if(this.phoneNumberVal) {
        formData.customer_phone_number = this.phoneNumberVal.formattedNumber
      }

      if(formData.notes && formData.notes.length > 0) {
        let notes = formData.notes.filter(el => el.key && el.value);
        formData.notes = notes && notes.length > 0 ? notes : null;
      } else {
        formData.notes = null;
      }

      if(formData.subscription_type === 'REGULAR') {
        if(formData.additional_costs && formData.additional_costs.length > 0) {
          let additional_costs = formData.additional_costs.filter(el => el.key && el.value);
          formData.additional_costs = additional_costs && additional_costs.length > 0 ? additional_costs : null;
        } else {
          formData.additional_costs = null;
        }
  
        if(this.immediately) {
          formData.started_at = "";
        }

        delete formData.initial_amount;
      }

      formData.country_code = this.currencies[formData.price.currency];

      if(formData.subscription_type === 'ONDEMAND') {
        delete formData.recurrance_count;
        delete formData.additional_costs;
        delete formData.started_at;
        delete formData.quantity;
        delete formData.allow_accumulate;
      }

      this.$store
        .dispatch(`subscription/${POST_SUBSCRIPTION}`, formData)
        .then((response) => {
          this.orderLink = response.subscription_link
          this.subscription_link_ref = response.order_ref
          // this.$notify({
          //   type: "success",
          //   text: "Subscription Created successfully.",
          //   closeOnClick: true,
          // });
          this.$refs['modal-2'].show()
        })
        .catch((error) => {
          // error
          console.log("error", error);
          this.$notify({
            type: "error",
            text: this.$store.state.notification.message,
            closeOnClick: true,
          });
        })
        .finally(() => {
          this.isProcessing = false;
          // setTimeout(() => {
          //   return this.closeSubscriptionModal();
          // }, 1000);
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    closeSubscriptionModal() {
      document.querySelector(".mdi-close").click();
    },
    addField() {
      this.form.notes.push({
        key: "",
        value: "",
      });
    },
    addAdditionalCost() {
      this.form.additional_costs.push({
        key: "",
        value: "",
      });
    },
    disabledBeforeTodayAndBeforeStart(date) {
      if(this.form.started_at && this.form.subscription_type === 'REGULAR') {
        return date > new Date(this.form.started_at).setHours(23, 59, 59, 59)  || date < new Date().setHours(0, 0, 0, 0);
      }
      return date < new Date();
    },
    disabledBeforeToday(date) {
      return date < new Date().setHours(0, 0, 0, 0);
    },
    updatePhoneNumber(e) {
      this.phoneNumberVal = e;
      this.form.customer_phone_number = e.nationalNumber ? e.nationalNumber : e.phoneNumber;
    },
    formatAmount(amount, currency) {
      let choosenLanguage = this.$store.state.auth.language || 'en';
      let formattedValue = new Intl.NumberFormat(choosenLanguage, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
      }).format(amount);
      return formattedValue;
    },
    removeAdditionalCost(index) {
      this.form.additional_costs.splice(index, 1)
    },
    removeAdditionalNotes(index) {
      this.form.notes.splice(index, 1)
    },
    planSelected(event) {
      this.form.subscription_type = event.plan_type;
      if(event.plan_type === 'ONDEMAND') {
        this.form.expiry_date = new Date(new Date().getTime() + 5 * 24 * 3600 * 1000)
      }
    },
    accumulateToggle() {
      if(this.form.allow_accumulate == 'Y'){
        this.form.allow_accumulate = 'N'
      }else{
        this.form.allow_accumulate = 'Y'
      }
    },
    currentDate(){
      return moment().format("DD MMM,YYYY")
    },
    hideModal() {
        this.$refs['modal-2'].hide()
    },
    onCopySuccess() {
      this.$notify({
          type: "success",
          text: "Subscription Link copied to clipboard.",
          closeOnClick: true,
      });
    },
    resetForm() {
      this.form.price = "";
      this.form.description = "";
      this.form.quantity = "";
      this.form.notes = null;
      this.form.allow_accumulate = "N";
      this.form.started_at = "";
      this.form.customer_name = "";
      this.form.customer_phone_number = "";
      this.form.customer_email_address = "";
      this.form.recurrance_count = 1;
      this.form.additional_costs = null;
      this.form.notify_by_email = false;
      this.form.notify_by_phone = false;
      this.form.initial_amount = null;
      this.form.expiry_date = "";
      this.form.subscription_type = "REGULAR";

      this.$nextTick(() => {
        this.$v.form.$reset();
      });

    }
  },
};
</script>

<template>
<Layout>
    <div class="d-flex" style="height: 44px;">
      <breadcrumb :items="items" :goBackTo="{ name: 'Subscription', query: {tab: 'Subscription'} }" />
      <div class="ml-auto">
        <router-link class="text-chai font-16px" :to="{ name: 'Subscription', query: {tab: 'Subscription'} }">
          <b-btn
            variant="white"
            :disabled="this.isProcessing"
            style="padding: 13px 16px;height: 44px;border: 1px solid #000;margin-right: 8px;"
          >
            cancel
          </b-btn>
        </router-link>
        <b-btn
          variant="primary"
          @click="submitSubscription"
          style="height: 44px;padding: 13px 16px;"
        >{{ $t('subscription.create_subscription') }}</b-btn>
      </div>
    </div>
  <div style="margin-top: 25px;">
    <form class="row" >
      <div class="col-lg-8 col-8">
        <div class="top-section">
          <div class="top-section-header">
              <div style="font-size: 16px;color: #000000;font-weight: 350;">Create New Subscription</div>
              <div style="font-size: 12px;color: #00000080;margin-top: 10px;font-weight: 325;">Create New Subscription to collect payment from your customers.</div>
          </div>
          <div class="top-section-body" style="padding: 20px 20px 20px 10px;">
            <div class="col-lg-12">
            <b-form-group
              invalid-feedback="Plan is required"
              :state="validateState('price')"
            >
              <label> {{ $t('subscription.select_pan') }}<span class="text text-danger">*</span></label>
              <b-form-select
                v-model="$v.form.price.$model"
                @change="planSelected"
                :class="{
                  'is-invalid': $v.form.price.$error,
                }"
              >
                <b-form-select-option value="">-- {{ $t('subscription.select_pan') }} --</b-form-select-option>
                <b-form-select-option :value="price" v-for="(price, key) in priceList" :key="key">{{ price.name }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <!-- <b-card v-if="form.price">
              <b-card-text>
                <b-badge class="font-size-12 mr-2">Name</b-badge>{{ form.price.name }}
              </b-card-text>
              <b-card-text>
                <b-badge class="font-size-12 mr-2">Amount</b-badge>{{ amount }}
              </b-card-text>
              <b-card-text>
                <b-badge class="font-size-12 mr-2">Frequency</b-badge>{{ frequency }}
              </b-card-text>
            </b-card> -->

            <b-form-group
              invalid-feedback="Description is required."
              :state="validateState('description')"
            >
              <label> {{ $t('subscription.description') }}</label>
              <textarea
                v-model.trim="$v.form.description.$model"
                type="text"
                class="form-control"
                placeholder="Product name for the customer's subscription purchase"
                name="description"
                :maxlength="200"
              />
              <div style="margin-top: 5px;">
                <span style="color: #FC6B2D;font-size: 14px;">Note :</span><span style="opacity: 0.50;color: black;font-size: 12px;padding-left: 10px;">Maximum 200 characters are allowed.</span>
              </div>
            </b-form-group>

            <b-form-group
              v-if="form.subscription_type === 'REGULAR'"
              invalid-feedback="Quantity is required and must be greater than 0."
              :state="validateState('quantity')"
            >
              <label> {{ $t('subscription.quantity') }}</label>
              <b-form-input
                v-model.number="$v.form.quantity.$model"
                class="form-control"
                :placeholder="$t('subscription.quantity')"
                name="quantity"
                type="number"
                :state="validateState('quantity')"
                :class="{
                  'is-invalid': typesubmit && $v.form.quantity.$error,
                }"
              />
            </b-form-group>

            <div class="form-group" v-if="form.subscription_type === 'ONDEMAND'">
              <label style="display: block;">{{ $t("Initial Amount") }}<span class="text text-danger">*</span></label>
              <!-- <b-input-group> -->
                <div style="display: inline-block;margin-right: 8px;width: 30%; vertical-align: top;">
                  <!-- <label> {{ $t('views.payment_links.currency') }}<span class="text text-danger">*</span></label> -->
                  <b-form-select
                    name="currency"
                    v-model="form.price.currency"
                    class="form-control custom-select-for-flag"
                    :options="currencyList"
                    disabled
                  >
                  </b-form-select>
                </div>
                <div style="display: inline-block;width: calc(70% - 8px); vertical-align: top;">
                  <!-- <label> {{ $t('common.amount') }}<span class="text text-danger">*</span></label>   -->
                  <b-form-input
                    type="number"
                    step="any"
                    v-model.number="$v.form.initial_amount.$model"
                    :state="validateState('initial_amount')"
                    placeholder="Amount"
                  ></b-form-input>
                  <div v-if="$v.form.initial_amount.$error" class="invalid-feedback">
                    {{ "Initial amount is required and must be positive number" }}
                  </div>
                </div>
                <!-- <template #prepend>
                  <div> -->
                    <!-- <img
                      v-if="form.price.currency == 'VND'"
                      class="flag_images"
                      src="@/assets/images/flags/vietnam1.png"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'THB'"
                      class="flag_images"
                      src="@/assets/images/flags/thailand.jpeg"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'SGD'"
                      class="flag_images"
                      src="@/assets/images/flags/singapore.png"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'IDR'"
                      class="flag_images"
                      src="@/assets/images/flags/indonesia.png"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'PHP'"
                      class="flag_images"
                      src="@/assets/images/flags/philippines.png"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'MYR'"
                      class="flag_images"
                      src="@/assets/images/flags/malaysia.svg"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'TWD'"
                      class="flag_images"
                      src="@/assets/images/flags/taiwan.png"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'HKD'"
                      class="flag_images"
                      src="@/assets/images/flags/hong_kong.svg"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'KHR'"
                      class="flag_images"
                      src="@/assets/images/flags/cambodia.svg"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'WST'"
                      class="flag_images"
                      src="@/assets/images/flags/samoa.svg"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'USD'"
                      class="flag_images"
                      src="@/assets/images/flags/united_states.svg"
                      alt=""
                    />
                    <img
                      v-if="form.price.currency == 'INR'"
                      class="flag_images"
                      src="@/assets/images/flags/india.svg"
                      alt=""
                    /> -->
                  <!-- </div>
                </template> -->
              <!-- </b-input-group> -->
            </div>

            <div class="form-group">
              <!-- <b-form-checkbox
                v-if="form.subscription_type === 'REGULAR'"
                v-model="$v.form.allow_accumulate.$model"
                value="Y"
                unchecked-value="N"
                class="mb-2"
              >
                {{ $t('subscription.allow_accumulate') }}
              </b-form-checkbox> -->
              <div v-if="form.subscription_type === 'REGULAR'" class="d-flex">
                  <label style="margin-bottom: 0px;" class="d-flex">{{ $t('subscription.allow_accumulate') }}</label>
                  <switch-input style="margin-bottom: 0px;" class="ml-auto" @input="accumulateToggle()" :value="$v.form.allow_accumulate.$model == 'Y'" />
              </div>
              <div v-if="form.subscription_type === 'REGULAR'" style="opacity: 0.5;color: black;font-size: 12px;margin-bottom: 16px;font-weight: 325;">Enable this option to accumulate the subscription amount and deduct it in the next billing cycle.</div>
              <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 20px;margin-top: 20px;"></div>
              <b-form-checkbox
                v-model="additional_notes"
                :value="true"
                :unchecked-value="false"
                class="mb-2 additional_notes_label"
              >
                {{ $t('subscription.additional_notes') }}
              </b-form-checkbox>
              <div v-if="additional_notes">
                <div
                  v-for="(note, index) in $v.form.notes.$each.$iter"
                  :key="index"
                  class="d-flex justify-content-between mb-2"
                >
                  <div class="w-50 mr-1">
                    <b-form-input
                      v-model="note.key.$model"
                      :placeholder="$t('subscription.title')"
                      trim
                      :class="{
                        'is-invalid': typesubmit && !note.key.required,
                      }"
                    ></b-form-input>
                    <div
                      class="invalid-feedback"
                      v-if="typesubmit && !note.key.required"
                    >
                      This value is required
                    </div>
                  </div>
                  <div class="w-50 mx-1">
                    <b-form-input
                      v-model="note.value.$model"
                      :placeholder="$t('subscription.description')"
                      trim
                      :class="{
                        'is-invalid': typesubmit && !note.value.required,
                      }"
                    ></b-form-input>
                    <div
                      class="invalid-feedback"
                      v-if="typesubmit && !note.value.required"
                    >
                      This value is required
                    </div>
                  </div>
                    <svg style="line-height: 43px;height: 43px;cursor: pointer;"  @click="removeAdditionalNotes(index)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g id="Iconsax/Linear/add">
                        <path id="Vector" d="M7.24264 16.2426L15.7279 7.75736M15.7279 16.2426L7.24264 7.75736" stroke="#E36464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                    </svg>
                  <!-- <b-btn variant="danger" >X</b-btn> -->
                </div>
              </div>
              <b-btn
                v-if="additional_notes"
                class="bg-white border mt-2 text-chai"
                size="sm"
                variant="light"
                @click="addField"
                style="border: unset !important;padding-left: 0px;"
              >
                 + {{ $t('subscription.add_field') }}
              </b-btn>
            </div>
            <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 20px;"></div>

            <b-form-group
              label=""
              invalid-feedback="Start Date required"
              :state="validateState('started_at')"
              v-if="form.subscription_type === 'REGULAR'"
            >
              <!-- <label>{{ $t('subscription.start_date') }}<span class="text text-danger">*</span></label> -->
              <div class="d-flex" style="justify-content: space-between;">
                <b-form-checkbox
                  v-model="immediately"
                  name="immediately"
                  :value="false"
                  :unchecked-value="true"
                  class="my-auto"
                >
                Schedule Subscription Start Date
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="immediately"
                  name="immediately"
                  :value="true"
                  :unchecked-value="false"
                  class="my-auto"
                >
                  Start {{ $t('subscription.immediately') }}
                </b-form-checkbox>
              </div>
                <date-picker
                  v-if="!immediately"
                  type="datetime"
                  :placeholder="$t('subscription.date_created')"
                  format="DD-MM-YYYY HH:mm"
                  v-model="$v.form.started_at.$model"
                  @input="setExpiryDate"
                  name="startdate"
                  class="custom-datepicker w-50"
                  prefix-class="xmx"
                  :clearable="false"
                  :disabled-date="disabledBeforeToday"
                >
                <i slot="icon-calendar">
                      <img src="@/assets/images/calendar.png" alt="Filter">
                  </i>
                </date-picker>
            </b-form-group>
            <div v-if="form.subscription_type === 'REGULAR'" style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 20px;"></div>

            <div class="form-group" v-if="form.subscription_type === 'REGULAR'">
              <b-form-checkbox
                v-model="additional_costs"
                :value="true"
                :unchecked-value="false"
                class="mb-2 additional_costs_label"
              >
                {{ $t('subscription.additional_costs') }}
              </b-form-checkbox>
              <div v-if="additional_costs">
                <div
                  v-for="(cost, index) in $v.form.additional_costs.$each.$iter"
                  :key="index"
                  class="d-flex justify-content-between mb-2"
                >
                  <div class="w-50 mr-1">
                    <b-form-input
                      v-model="cost.key.$model"
                      placeholder="Type of cost"
                      trim
                      :class="{
                        'is-invalid': typesubmit && cost.key.$invalid,
                      }"
                    ></b-form-input>
                    <div
                      class="invalid-feedback"
                      v-if="typesubmit && cost.key.$invalid"
                    >
                      This value is required
                    </div>
                  </div>
                  <div class="w-50 mx-1">
                    <b-form-input
                      v-model.number="cost.value.$model"
                      type="number"
                      placeholder="Amount"
                      trim
                      :class="{
                        'is-invalid': typesubmit && cost.value.$invalid,
                      }"
                    ></b-form-input>
                    <div
                      class="invalid-feedback"
                      v-if="typesubmit && cost.value.$invalid"
                    >
                      This value is required and must be greater than 0
                    </div>
                  </div>
                    <svg style="line-height: 43px;height: 43px;cursor: pointer;"  @click="removeAdditionalCost(index)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g id="Iconsax/Linear/add">
                        <path id="Vector" d="M7.24264 16.2426L15.7279 7.75736M15.7279 16.2426L7.24264 7.75736" stroke="#E36464" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                    </svg>
                  <!-- <b-btn variant="danger" @click="removeAdditionalCost(index)">X</b-btn> -->
                </div>
              </div>
              <b-btn
                v-if="additional_costs"
                class="bg-white border mt-2 text-chai"
                size="sm"
                variant="light"
                @click="addAdditionalCost"
                style="border: unset !important;padding-left: 0px;"
              >
               + Add Additional Cost
              </b-btn>
            </div>

            <div v-if="form.subscription_type === 'REGULAR'" style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 20px;"></div>

            <div class="row">
              <b-form-group
                v-if="form.subscription_type === 'REGULAR'"
                invalid-feedback="Recurrance Count must be positve integer"
                class="col-sm-4"
              >
                <label>{{ $t('subscription.recurrance_count') }}<span class="text text-danger">*</span></label>
                <b-form-input
                  v-model.number="$v.form.recurrance_count.$model"
                  placeholder="Recurrance Count"
                  name="recurrance_count"
                  :state="validateState('recurrance_count')"
                  :class="{
                    'is-invalid': typesubmit && $v.form.recurrance_count.$error,
                  }"
                />
              </b-form-group>

              <div class="col-sm-8">
                <b-form-group
                  invalid-feedback="Expiry Date is required"
                  :state="validateState('expiry_date')"
                >
                  <label>{{ $t('subscription.link_expiry') }}<span class="text text-danger">*</span></label>
                  <date-picker
                    type="datetime"
                    :placeholder="$t('subscription.expiry_date')"
                    format="DD-MM-YYYY HH:mm"
                    v-model="$v.form.expiry_date.$model"
                    name="expiry_date"
                    class="custom-datepicker w-100 d-block"
                    prefix-class="xmx"
                    :disabled-date="disabledBeforeTodayAndBeforeStart"
                  >
                  <i slot="icon-calendar">
                      <img src="@/assets/images/calendar.png" alt="Filter">
                  </i>
                  </date-picker>
                </b-form-group>
              </div>
            </div>
             <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 20px;"></div>

            <div>
              <b-form-group
                invalid-feedback="Customer name is required"
              >
                <label>{{ $t('views.payment_links.customer_name') }}</label>
                <b-form-input
                  v-model="$v.form.customer_name.$model"
                  :placeholder="$t('subscription.customer_name')"
                  name="customer_name"
                  :class="{
                    'is-invalid': typesubmit && $v.form.customer_name.$error,
                  }"
                />
              </b-form-group>

              <b-form-group
                invalid-feedback="Customer email is required"
                :class="{
                  'is-invalid': typesubmit && $v.form.customer_email_address.$error,
                }"
              >
                 <label>{{ $t('views.payment_links.customer_email') }}</label>
                <b-form-input
                  v-model="$v.form.customer_email_address.$model"
                  type="email"
                  :placeholder="$t('subscription.customer_email')"
                  name="customer_email_address"
                  :class="{
                    'is-invalid': typesubmit && $v.form.customer_email_address.$error,
                  }"
                />
                  <b-form-checkbox
                   style="margin-top: 15px;"
                    v-model="$v.form.notify_by_email.$model"
                    :value="true"
                    :unchecked-value="false"
                    class="email-sms-label"
                  >
                    Send notification via email
                  </b-form-checkbox>
              </b-form-group>

              <b-form-group
                invalid-feedback="Customer phone number must be valid phone number"
              >
                <label>{{ $t('views.payment_links.customer_phone') }}</label>
                <VuePhoneNumberInput
                  :value="$v.form.customer_phone_number.$model"
                  id="phone_number"
                  name="phone_number"
                  valid-color="#1cbb8c"
                  :default-country-code="$store.state.auth.currentUser.country"
                  @update="updatePhoneNumber($event)"
                  :preferred-countries="['VN', 'TH', 'ID', 'SG', 'KR', 'PH', 'IN', 'MY']"
                  class="w-100 rounded"
                  :class="(typesubmit && $v.form.customer_phone_number.$error) || (typesubmit && form.customer_phone_number && phoneNumberVal && !phoneNumberVal.isValid) ? `border border-danger is-invalid` : (typesubmit && form.customer_phone_number ? `is-valid` : ``)"
                />
                  <b-form-checkbox
                   style="margin-top: 15px;"
                    v-model="$v.form.notify_by_phone.$model"
                    :value="true"
                    :unchecked-value="false"
                    class="email-sms-label"
                  >
                    Send notification via contact number
                  </b-form-checkbox>
              </b-form-group>
            </div>
            <!-- <div
              class="col-lg-12 my-1 text-right bg-white"
              style="position: sticky; bottom: 0px; padding-bottom: 15px; z-index: 10;"
            >
              <div
                style="
                  background: #fff;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  bottom: 0px;
                  opacity: 0.5;
                "
              ></div>
              <div style="width: 90%; margin: 0 auto; position: relative">
                <button
                  style="width: 100%"
                  type="submit"
                  :disabled="isProcessing"
                  class="btn btn-primary"
                >
                  {{ $t('subscription.create_subscription') }}
                </button>
                <button
                  style="width: 100%; margin-top: 10px"
                  @click="closeSubscriptionModal"
                  id="close-payment-link-form"
                  class="btn btn-light mr-2"
                >
                  {{ $t("button.close") }}
                </button>
              </div>
            </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-4">
          <div style="padding: 25px;box-shadow: 0px 4px 15px 1px rgba(220, 218, 213, 0.30);border-radius: 12px;border: 1px rgba(220, 218, 213, 0.50) solid;">
              <p style="font-size: 20px;color: #FC6B2D; margin-bottom: 25px;}">Subscription Summary</p>
              <label style="color: black;font-size: 14px;margin-bottom: 10px;">Plan Details</label>

              <div style="margin-bottom: 16px;" class="summary-details-section">
                <div class="summary-name">Plan Name</div>
                <div class="summary-details">{{form.price.name}}</div>
              </div>
              <div style="margin-bottom: 16px;" class="summary-details-section">
                <div class="summary-name">Amount</div>
                <div class="summary-details">{{amount}}</div>
              </div>
              <div style="margin-bottom: 16px;" class="summary-details-section">
                <div class="summary-name">Frequency</div>
                <div class="summary-details">{{frequency ? frequency : ''}}</div>
              </div>
              <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 16px; margin-top: 25px;"></div>

              <p style="font-size: 14px;color: #000000; margin-bottom: 16px;}">Subscription Details</p>
              <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px; margin-bottom:8px;">
                  <div>Created At</div>
                  <div style="margin-left: auto;order: 2; color: #000;">{{  currentDate() }}</div>
              </div>
              <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px; margin-bottom:8px;">
                  <div>Quantity</div>
                  <div style="margin-left: auto;order: 2; color: #000;">{{form.quantity}}</div>
              </div>
              <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px; margin-bottom:8px;">
                  <div>Recurrence Count</div>
                  <div style="margin-left: auto;order: 2; color: #000;">{{  form.recurrance_count }}</div>
              </div>
              <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px;">
                  <div>Link Expires On</div>
                  <div style="margin-left: auto;order: 2; color: #000;">{{  form.expiry_date | expiry_date }}</div>
              </div>
              <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 16px; margin-top: 25px;"></div>

              <p style="font-size: 14px;color: #000000; margin-bottom: 12px;}">Customer Details</p>
              <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px; margin-bottom:8px;">
                  <div>Customer Name</div>
                  <div style="margin-left: auto;order: 2; color: #000;">{{  form.customer_name }}</div>
              </div>
              <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px; margin-bottom:8px;">
                  <div>Customer Email</div>
                  <div style="margin-left: auto;order: 2; color: #000;">{{ form.customer_email_address }}</div>
              </div>
              <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px;">
                  <div>Contact Number</div>
                  <div style="margin-left: auto;order: 2; color: #000;">{{  form.customer_phone_number }}</div>
              </div>
          </div>
      </div>
    </form>
    <div v-show="orderLink" class="col-lg-12 my-1 text-center">
      <!-- <img src="@/assets/images/success_new.svg" alt />
      <p class="text text-success" style="font-size: 22px;margin-top: 25px;">
        Thank you
      </p>
      <p class="text" style="font-size: 22px;">
        Subscription Generated Successfully
      </p>
      <copy-link :orderLink="orderLink" successMessage="Subscription Link copied to clipboard." />
      <div style="font-size: 18px;margin-top: 30px;text-decoration: underline;">
        <a target="_blank" :href="orderLink">Test your link here</a>
      </div> -->

      
              <b-modal
                id="modal-2"
                ref="modal-2"
                title=""
                hide-footer
                @hidden="resetForm"
                no-close-on-backdrop
                header-class="subs-modal-header-custom"
                body-class="subs-modal-body-custom"
                footer-class="subs-modal-footer-custom"
            >
                <div class="text-center">
                    <div class="text-black" style="font-size: 24px; margin-bottom: 5px;">Congratulations!</div>
                    <div style="color: rgba(0, 0, 0, 0.5);font-size: 14px;line-height: 20px;margin-top: 12px;margin-bottom: 30px;">Your Subscription has been created successfully.</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
                      <g id="Group 238390">
                        <g id="Group 238387">
                          <circle id="Ellipse 8" cx="50" cy="50" r="50" fill="#FC6B2D" fill-opacity="0.1"/>
                        </g>
                        <g id="Group 238389">
                          <g id="vuesax/twotone/refresh-2">
                            <g id="refresh-2">
                              <path id="Vector" d="M29.166 49.9994C29.166 38.4994 38.416 29.166 49.9994 29.166C63.8952 29.166 70.8327 40.7494 70.8327 40.7494M70.8327 40.7494V30.3327M70.8327 40.7494H61.5827" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path id="Vector_2" d="M70.6042 50C70.6042 61.5 61.2708 70.8333 49.7708 70.8333C38.2708 70.8333 31.25 59.25 31.25 59.25M31.25 59.25H40.6667M31.25 59.25V69.6667" stroke="black" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                          </g>
                        <g id="Group 576">
                          <path id="Vector_3" d="M45 53.1071C45 54.8271 46.4843 56.2138 48.3283 56.2138H52.0915C53.6957 56.2138 55 55.0004 55 53.5071C55 51.8804 54.2054 51.3071 53.021 50.9338L46.979 49.0671C45.7946 48.6938 45 48.1204 45 46.4938C45 45.0004 46.3043 43.7871 47.9085 43.7871H51.6717C53.5157 43.7871 55 45.1738 55 46.8938" stroke="#FC6B2D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path id="Vector_4" d="M49.9902 42V58" stroke="#FC6B2D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </g>
                      </g>
                    </svg>
                    <div class="" style="border: 1px solid rgb(220, 218, 213);border-radius: 8px;color: rgba(0, 0, 0, 0.7);margin-top: 20px;padding-top: 13px;padding-bottom: 13px;padding-left: 16px;background: white;box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px;">
                        <span class="text-truncate" style="font-size: 14px;width: calc(100% - 40px);display: inline-block;">{{ orderLink }}</span>
                        <span  v-clipboard:copy="orderLink"
                        v-clipboard:success="onCopySuccess"
                        style="cursor: pointer;padding-left: 10px;padding-right: 0px;width: 36px;height: 36px;display: inline-block;float: right;background: rgb(217, 217, 217);border-radius: 8px;position: relative;bottom: 10px;right: 4px;">
                          <svg style="position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                            <g id="Iconsax/Linear/copy" opacity="0.5">
                            <path id="Vector" d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path id="Vector_2" d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                        </span>
                    </div>
                    <div style="font-size: 14px;margin-top: 12px;text-decoration: underline; color:#FC6B2D;">
                        <a target="_blank"  style="color:#FC6B2D;" :href="orderLink">Check Subscription Link Preview Here 
                          <svg style="margin-left: 10px;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                            <g id="Iconsax/Linear/exportsquare">
                            <path id="Vector" d="M13 11L21.2 2.8M22 6.8V2H17.2M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                          </svg>
                        </a>
                    </div>
                    <div class="d-flex justify-content-center">
                      <div style="margin-top: 40px;margin-bottom: 40px;">
                        <b-btn style="padding: 12px 25px;" @click="$router.push({path: '/subscriptions/details', query: { pageRef: subscription_link_ref }})"  variant="primary" class="mr-2 bg-white text-dark">View Details</b-btn>
                        <b-btn style="padding: 12px 25px; margin-left: 25px;" variant="primary" @click="hideModal">Create New Subscription</b-btn>
                      </div>
                    </div>
                </div>
        </b-modal>



    </div>
  </div>
</Layout>
</template>

<style>
.xmx-datepicker-popup {
  z-index: 9999;
}
.subs-modal-header-custom{
    border-bottom: none;
    padding-bottom: 0px;
}
.subs-modal-body-custom{
    padding: 0px 35px 10px 35px;
}
.subs-modal-footer-custom{
    border-top: none;
    padding: 0px 55px 25px 55px;
}
</style>
<style lang="css" scoped>
::v-deep .custom-select-for-flag {
  padding-left: 35px;
}
::v-deep .flag_images {
  position: absolute;
  width: 25px;
  top: 10px;
  left: 10px;
}
.custom-control {
  z-index: unset;
}
.top-section-header{
  background: #dcdad54d;
  padding: 18px 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px #DCDAD5 solid;
}
.top-section{
    box-shadow: 0px 4px 12px 1px rgba(220, 218, 213, 0.30);
    border-radius: 15px;
}
::v-deep .top-section label{
    color: black;
    font-size: 14px;
    margin-bottom: 10px;
}
.top-section .form-group{
    margin-bottom: 20px;
}
::v-deep .top-section select, ::v-deep .top-section input, ::v-deep .top-section textarea{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    font-size: 14px;
    color: #000;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border: 1px #DCDAD5 solid;
    height: auto;
}
.top-section select{
    padding-top: 13.5px;
    padding-bottom: 13.5px;
}
.top-section .flag_images{
    position: absolute;
    width: 28px;
    top: 36px;
    left: 20px;
    border-radius: 4px;
}
::v-deep .additional_notes_label label{
  margin-bottom: 0px;
}
::v-deep .additional_costs_label label{
  margin-bottom: 0px;
}
::v-deep .top-section .xmx-input {
    /* padding-left: 30px; */
    padding: 13px 16px !important;
    padding-right: 38px !important;
    height: 44px !important;
    border-radius: 12px !important;
    border: 1px solid #DCDAD5 !important;
    background: transparent !important;
    font-size: 14px !important;
    font-weight: 350 !important;
    color: #000 !important;
}
::v-deep .xmx-icon-calendar,::v-deep .xmx-icon-clear{
    right: 15px;
}
.xmx-icon-calendar img{
    width: 17px;
    height: 17px;
}
::v-deep .email-sms-label label{
    font-size:12px; 
    color:#000 !important;
    margin-bottom: 0px;
}
.summary-details-section .summary-name{
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    line-height: 20px;
}
.summary-details-section .summary-details{
    font-size: 14px;
    line-height: 20px;
}
</style>